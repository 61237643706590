<template>
  <Page color="info" title="Kurse">
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <TermPicker outlined text v-model="term" :range="[-3, 1]" />
        <v-spacer></v-spacer>
        <v-text-field
          v-if="!$_hasRole('student')"
          placeholder="Fach, Kürzel, Klasse"
          v-model="search"
          clearable
          hide-details
          dense
        >
        </v-text-field>
        <v-btn
          class="ml-2"
          v-if="!$_hasRole('student')"
          outlined
          text
          @click="showMine()"
          >Meine</v-btn
        >
      </v-row>
    </v-container>
    <v-card>
      <v-system-bar>
        <span v-if="items.length == 0">keine Ergebnisse</span>
        <span v-else-if="description">{{ description }}</span>
      </v-system-bar>
      <CourseList :items="items" :search="search" />
    </v-card>
  </Page>
</template>

<script>
import { formatDatespan } from "common/utils/date.js";
import { subjectIcon } from "common/utils/icons.js";

import CourseList from "@/components/CourseList.vue";
import TermPicker from "common/components/TermPicker.vue";
export default {
  name: "Courses",
  components: { CourseList, TermPicker },
  data() {
    return {
      description: null,
      loading: false,
      search: "",
      term: null,
      items: [],
      searchNumber: 0,
    };
  },
  watch: {
    search() {
      this.fetchData();
    },
    term() {
      this.fetchData();
    },
  },
  methods: {
    formatDatespan,
    subjectIcon,
    async fetchData() {
      if (this.$_hasRole("student")) {
        this.fetchDataStudent();
      } else {
        this.fetchDataStaff();
      }
    },
    async fetchDataStudent() {
      if (!this.term) return;
      this.items = await this.apiList({
        resource: "course/course",
        query: `person=${this.$_profilePerson.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
    },
    async fetchDataStaff() {
      this.loading = true;

      if (!this.term || !this.search || this.search.length < 2) {
        this.items = [];
        return;
      }
      const search = this.search;
      this.items = [];
      const searchNumber = ++this.searchNumber;
      const result = await this.apiList({
        resource: "course/course",
        query: `search=${search}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      if (searchNumber === this.searchNumber) {
        this.items = result.courses;
        this.description = result.description;
        localStorage.setItem("course_search", search);
        if (!this.$route.query.search || this.$route.query.search != search) {
          this.$router.replace({ query: { search: search } });
        }
      }
      this.loading = false;
    },
    showMine() {
      this.search = this.$_profilePerson.code;
    },
  },
  created() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    } else if (localStorage.getItem("course_search")) {
      this.search = localStorage.getItem("course_search");
    }
  },
};
</script>
